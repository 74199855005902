import React, { useEffect, useState } from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { transformToSlug } from 'helpers/slugHelper';
import { PATHS } from '@global/constants/urls';
import NoResultFilter from '@components/NoResultFilter/NoResultFilter';
import SearchIcon from './assets/search-icon.svg';
import ArrowIcon from './assets/ArrowIcon';
import CalendarIcon from './assets/calendar.svg';
import {
  TrainingContainer,
  Container,
  DataContainer,
  FilterContainer,
  SearchImage,
  ImageContainer,
  SearchInput,
  SubtitleContainer,
  Tag,
  TitleContainer,
  Wrapper,
  SearchContainer,
  FilterWrapper,
  TitleFilterContainer,
  FilterTitle,
  ClearFilter,
  IconContainer,
  FilterInput,
  CheckboxInput,
  FilterButton,
  AllWebinarContainer,
  AllWebinarTitle,
  SeparateLine,
  CalendarContainer,
  AllWebinarText,
  AllWebinarCTA,
  WebinarTitleContainer,
  WebinarSchedule,
  WebinarDate,
  WebinarTitleTooltip,
  WebinarContainer,
} from './webinarList.styles';
import { WebinarPostsListProps, WebinarsProps } from './WebinarList.types';

const WebinarPostsList = ({
  webinars,
  topicsFilter,
  careersFilter,
  rolesFilter,
}: WebinarPostsListProps) => {
  const [careerFilterOpen, setCareerFilterOpen] = useState<boolean>(false);
  const [topicFilterOpen, setTopicFilterOpen] = useState<boolean>(false);
  const [roleFilterOpen, setRoleFilterOpen] = useState<boolean>(false);
  const [filterApplied, setFilterApplied] = useState<string[]>([]);
  const [renderWebinars, setRenderWebinars] = useState(webinars);
  const [searchInput, setSearchInput] = useState('');
  const [confirmSearchInput, setConfirmSearchInput] = useState('');
  const [renderFilters, setRenderFilters] = useState(false);
  const [renderTooltip, setRenderTooltip] = useState<string[]>();

  const handelFilterApplied = (value: string) => {
    if (filterApplied.find((filter) => filter === value)) {
      setFilterApplied(filterApplied.filter((filter) => filter !== value));
    } else {
      setFilterApplied([...filterApplied, value]);
    }
  };

  const handleSearch = (arrayWebiars: WebinarsProps[]) => {
    if (confirmSearchInput !== '') {
      const filterWebinars = arrayWebiars.filter((renderWebinar) => {
        return renderWebinar.attributes.Title.toLowerCase().includes(
          confirmSearchInput.toLowerCase(),
        );
      });
      setRenderWebinars([...new Set(filterWebinars)]);
    } else {
      setRenderWebinars([...new Set(arrayWebiars)]);
    }
  };

  const handleClearFilters = () => {
    setFilterApplied([]);
  };

  const handleFilterTrainings = (arrayWebiars: WebinarsProps[]) => {
    const webiarsToRender: WebinarsProps[] = [];
    arrayWebiars.forEach((webinarPosts) => {
      if (webinarPosts.attributes.Topics.data.length > 0) {
        webinarPosts.attributes.Topics.data.forEach((topic) => {
          if (filterApplied.includes(topic.attributes.Topic)) {
            webiarsToRender.push(webinarPosts);
          }
        });
      }
      if (webinarPosts.attributes.Roles.data.length > 0) {
        webinarPosts.attributes.Roles.data.forEach((role) => {
          if (filterApplied.includes(role.attributes.Role)) {
            webiarsToRender.push(webinarPosts);
          }
        });
      }
      if (webinarPosts.attributes.Career_Clusters.data.length > 0) {
        webinarPosts.attributes.Career_Clusters.data.forEach((career) => {
          if (filterApplied.includes(career.attributes.Career_Cluster)) {
            webiarsToRender.push(webinarPosts);
          }
        });
      }
    });

    return webiarsToRender;
  };
  const handleFilterViaQueryParam = (urlParams: URLSearchParams) => {
    const topic = urlParams.get('topic');
    const role = urlParams.get('role');
    const career = urlParams.get('career');
    if (topic) {
      setFilterApplied([topic]);
      setTopicFilterOpen(true);
    }
    if (role) {
      setFilterApplied([role]);
      setRoleFilterOpen(true);
    }
    if (career) {
      setFilterApplied([career]);
      setCareerFilterOpen(true);
    }
  };

  const handleSchedule = (schedule: string) => {
    const scheduleDate = new Date(schedule);
    const scheduleHours = scheduleDate.getHours();
    const scheduleMinutes = scheduleDate.getMinutes();
    const scheduleWeekDate = scheduleDate.toLocaleString('en-us', { weekday: 'long' });

    if (!Number.isNaN(scheduleHours) && !Number.isNaN(scheduleMinutes)) {
      return (
        <>
          {scheduleDate.getDate()}
          <WebinarDate>
            {scheduleDate
              .toLocaleString('default', {
                month: 'short',
              })
              .toUpperCase()}
            <SeparateLine>|</SeparateLine>
            {scheduleWeekDate}
            <SeparateLine>|</SeparateLine>
            {scheduleHours > 10 ? scheduleHours : `0${scheduleHours}`}:
            {scheduleMinutes > 10 ? scheduleMinutes : `0${scheduleMinutes}`}
          </WebinarDate>
        </>
      );
    }
    return <WebinarDate>{schedule}</WebinarDate>;
  };

  useEffect(() => {
    if (filterApplied.length > 0) {
      const webinarToRender = handleFilterTrainings(webinars);
      handleSearch(webinarToRender);
    } else if (confirmSearchInput !== '') {
      handleSearch(webinars);
    }
    if (filterApplied.length === 0 && confirmSearchInput === '') {
      setRenderWebinars(webinars);
    }
  }, [webinars, filterApplied, confirmSearchInput]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    handleFilterViaQueryParam(urlParams);
    const input = document.getElementById('searchInput');
    if (input) {
      input.onkeydown = (event) => {
        if (event.code === 'Enter') {
          event.preventDefault();
          setConfirmSearchInput(event.target?.value);
        }
      };
    }

    const isEllipsisActive = (element) => {
      return element.offsetHeight + 2 < element.scrollHeight;
    };

    const elements = document.querySelectorAll('.training-title');
    const renderTooltipTitle: string[] = [];
    elements.forEach((el) => {
      if (isEllipsisActive(el)) {
        renderTooltipTitle.push(el.innerText);
      }
    });
    setRenderTooltip(renderTooltipTitle);
  }, []);

  return (
    <GlobalWrapper>
      <Container>
        <FilterContainer>
          <FilterButton onClick={() => setRenderFilters(!renderFilters)}>Filters</FilterButton>
          <SearchContainer>
            <SearchInput
              id="searchInput"
              placeholder="Search"
              type="text"
              onChange={(event) => setSearchInput(event.target.value)}
            />
            <SearchImage onClick={() => setConfirmSearchInput(searchInput)} src={SearchIcon} />
          </SearchContainer>
          <FilterWrapper renderFilters={renderFilters}>
            {(careersFilter.length > 0 || topicsFilter.length > 0 || rolesFilter.length > 0) && (
              <TitleFilterContainer>
                <FilterTitle>Filters</FilterTitle>
                <ClearFilter onClick={handleClearFilters}>Clear Filters</ClearFilter>
              </TitleFilterContainer>
            )}
            {careersFilter.length > 0 && (
              <>
                <TitleFilterContainer
                  isFilterOpen={careerFilterOpen}
                  alignCenter
                  onClick={() => setCareerFilterOpen(!careerFilterOpen)}
                >
                  <FilterTitle className="filter-title">Career Clusters</FilterTitle>
                  <IconContainer className="icon-container" isOpen={careerFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                {careerFilterOpen &&
                  careersFilter.map((career, index) => (
                    <FilterInput
                      key={career.attributes.Career_Cluster}
                      lastChild={careersFilter.length - 1 === index}
                    >
                      <CheckboxInput
                        name={career.attributes.Career_Cluster}
                        value={career.attributes.Career_Cluster}
                        checked={filterApplied.includes(career.attributes.Career_Cluster)}
                        onChange={() => handelFilterApplied(career.attributes.Career_Cluster)}
                        type="checkbox"
                      />
                      {career.attributes.Career_Cluster}
                    </FilterInput>
                  ))}
              </>
            )}
            {topicsFilter.length > 0 && (
              <>
                <TitleFilterContainer
                  isFilterOpen={topicFilterOpen}
                  alignCenter
                  onClick={() => setTopicFilterOpen(!topicFilterOpen)}
                >
                  <FilterTitle className="filter-title">Webinar</FilterTitle>
                  <IconContainer className="icon-container" isOpen={topicFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                {topicFilterOpen &&
                  topicsFilter.map((topic, index) => (
                    <FilterInput
                      key={topic.attributes.Topic}
                      lastChild={topicsFilter.length - 1 === index}
                    >
                      <CheckboxInput
                        name={topic.attributes.Topic}
                        value={topic.attributes.Topic}
                        checked={filterApplied.includes(topic.attributes.Topic)}
                        onChange={() => handelFilterApplied(topic.attributes.Topic)}
                        type="checkbox"
                      />
                      {topic.attributes.Topic}
                    </FilterInput>
                  ))}
              </>
            )}
            {rolesFilter.length > 0 && (
              <>
                <TitleFilterContainer
                  isFilterOpen={roleFilterOpen}
                  alignCenter
                  onClick={() => setRoleFilterOpen(!roleFilterOpen)}
                >
                  <FilterTitle className="filter-title">Role</FilterTitle>
                  <IconContainer className="icon-container" isOpen={roleFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                {roleFilterOpen &&
                  rolesFilter.map((role, index) => (
                    <FilterInput
                      key={role.attributes.Role}
                      lastChild={rolesFilter.length - 1 === index}
                    >
                      <CheckboxInput
                        name={role.attributes.Role}
                        value={role.attributes.Role}
                        checked={filterApplied.includes(role.attributes.Role)}
                        onChange={() => handelFilterApplied(role.attributes.Role)}
                        type="checkbox"
                      />
                      {role.attributes.Role}
                    </FilterInput>
                  ))}
              </>
            )}
          </FilterWrapper>
        </FilterContainer>
        <Wrapper>
          {renderWebinars.length > 0 ? (
            renderWebinars.map((webinarPosts, index) => (
              <>
                <WebinarContainer
                  key={webinarPosts.attributes.Title}
                  href={`${PATHS.WEBINAR}/${transformToSlug(
                    webinarPosts.attributes.Title.toLowerCase(),
                  )}`}
                >
                  <ImageContainer>
                    <Tag>Webinar</Tag>
                    {CMSParseImage(webinarPosts.attributes.Small_Image.data)}
                  </ImageContainer>
                  <DataContainer className="training-data">
                    <WebinarSchedule>
                      {handleSchedule(webinarPosts.attributes.Schedule)}
                    </WebinarSchedule>
                    <WebinarTitleContainer>
                      <TitleContainer className="training-title">
                        {webinarPosts.attributes.Title}
                      </TitleContainer>
                      {renderTooltip?.includes(webinarPosts.attributes.Title) && (
                        <WebinarTitleTooltip className="training-title-tooltip">
                          {webinarPosts.attributes.Title}
                        </WebinarTitleTooltip>
                      )}
                    </WebinarTitleContainer>
                    <SubtitleContainer>{webinarPosts.attributes.Subtitle}</SubtitleContainer>
                  </DataContainer>
                </WebinarContainer>
                {index === 4 && (
                  <AllWebinarContainer>
                    <CalendarContainer src={CalendarIcon} />
                    <AllWebinarTitle>Attend a Live, Virtual Webinar</AllWebinarTitle>
                    <AllWebinarText>Check out our webinar calendar and register.</AllWebinarText>
                    <AllWebinarCTA href={`${PATHS.CALENDAR}?type=webinar`}>View all calendar</AllWebinarCTA>
                  </AllWebinarContainer>
                )}
              </>
            ))
          ) : (
            <NoResultFilter />
          )}
        </Wrapper>
      </Container>
    </GlobalWrapper>
  );
};

export default WebinarPostsList;
