import { COLORS } from '@global/styles/colors';
import { DESKTOP_1280, MOBILE_575, TABLET_768, TABLET_992 } from '@global/styles/sizes';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 26px;
  @media (max-width: ${DESKTOP_1280}px) {
    flex-direction: column;
    gap: 30px;
  }
`;

export const Wrapper = styled.div`
  width: calc(100% - 206px);
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 26px;
  row-gap: 30px;

  @media (max-width: ${DESKTOP_1280}px) {
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 140px;
  position: relative;
  border-radius: 8px 8px 0 0;

  img {
    height: 140px !important;
    border-radius: 8px 8px 0 0;
    object-fit: cover;
    object-position: left;
  }
`;

export const WebinarContainer = styled.a`
  width: 31%;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    .training-data {
      background-color: #e2e8f0;
    }
  }

  @media (max-width: ${TABLET_992}px) {
    width: 47%;
  }

  @media (max-width: ${TABLET_768}px) {
    width: 100%;
  }
`;

export const DataContainer = styled.div`
  padding: 16px 16px 13px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 0 0 8px 8px;
  border: 1px solid #d5d5d5;
  border-top: none;
`;

export const TitleContainer = styled.div`
  font-family: 'Poppins';
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  color: #000000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SubtitleContainer = styled.div`
  min-height: 40px;
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.resolutionBlue};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Tag = styled.div`
  width: 101px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: ${COLORS.violetRed};
  border-radius: 0 8px 0 8px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: ${COLORS.white};
`;

export const FilterContainer = styled.div`
  width: 206px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: ${DESKTOP_1280}px) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column: -1 / 1;
    align-items: center;
  }
  @media (max-width: ${MOBILE_575}px) {
    flex-direction: column;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 36px;
  padding: 6px 40px 6px 15px;
  border-radius: 50px;
  border: 1px solid #d5d5d5;
  box-sizing: border-box;
  position: relative;
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 25px;

  &::placeholder {
    color: #000000;
  }

  @media (max-width: ${DESKTOP_1280}px) {
    width: 100%;
  }
`;

export const SearchContainer = styled.div`
  margin-bottom: 4px;
  position: relative;

  &:hover {
    #searchInput {
      background-color: #e2e8f0;
    }
  }
`;

export const SearchImage = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 16px;
  top: 8px;
  cursor: pointer;
`;

export const FilterWrapper = styled.div<{ renderFilters: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: ${DESKTOP_1280}px) {
    display: ${({ renderFilters }) => (renderFilters ? 'flex' : 'none')};
    grid-column: 1/-1;
  }
`;

export const TitleFilterContainer = styled.div<{ alignCenter?: boolean; isFilterOpen?: boolean }>`
  margin-bottom: ${({ isFilterOpen }) => (isFilterOpen ? '0' : '18px')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: ${({ alignCenter }) => (alignCenter ? 'center' : 'flex-end')};
  gap: 10px;
  cursor: pointer;

  &:hover {
    .filter-title {
      color: #0e8cb9;
    }
    .icon-container {
      svg {
        path {
          fill: #0e8cb9;
        }
      }
    }
  }
`;

export const FilterTitle = styled.div`
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #252a34;
`;

export const ClearFilter = styled.div`
  cursor: pointer;
  text-decoration: underline;
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 21px;
  color: #00a8cf;

  &:hover {
    color: #0e8cb9;
  }
`;

export const IconContainer = styled.div<{ isOpen: boolean }>`
  width: 12px;
  height: 6px;
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `}
  display: flex;
  align-items: center;
  img {
    width: 12px;
    height: 6px;
  }
`;

export const CheckboxInput = styled.input`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #b9ccd8;
  margin-right: 8px;
  cursor: pointer;
  &:checked {
    accent-color: #0e8cb9;
  }
`;

export const FilterInput = styled.label<{ lastChild?: boolean }>`
  ${({ lastChild }) =>
    lastChild &&
    css`
      margin-bottom: 18px;
    `}
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: 'Poppins';
  font-size: 13px;
  line-height: 18px;
  color: #4b5366;
`;

export const FilterButton = styled.div`
  width: 230px;
  height: 48px;
  display: none;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-family: 'Poppins';
  font-size: 20px;
  line-height: 30px;
  color: ${COLORS.white};
  background-color: #0e8cb9;
  border: 1px solid #0e8cb9;
  cursor: pointer;
  &:hover {
    color: #0e8cb9;
    background-color: ${COLORS.white};
  }

  @media (max-width: ${DESKTOP_1280}px) {
    display: flex;
  }
  @media (max-width: ${MOBILE_575}px) {
    width: 100%;
  }
`;

export const AllWebinarContainer = styled.div`
  width: 31%;
  padding: 28px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.violetRed};

  @media (max-width: ${TABLET_992}px) {
    width: 47%;
  }

  @media (max-width: ${TABLET_768}px) {
    width: 100%;
  }
`;

export const AllWebinarTitle = styled.div`
  margin-top: 15px;
  font-family: 'Poppins';
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: ${COLORS.white};
`;

export const AllWebinarText = styled.div`
  font-family: 'Poppins';
  font-size: 18px;
  line-height: 24px;
  color: ${COLORS.white};
  text-align: center;
`;

export const AllWebinarCTA = styled.a`
  width: 100%;
  height: 52px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  text-decoration: none;
  border-radius: 8px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.violetRed};
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${COLORS.violetRed};

  &:hover {
    color: ${COLORS.white};
    background-color: ${COLORS.violetRed};
    border: 1px solid ${COLORS.white};
  }
`;

export const SeparateLine = styled.div`
  margin: 0 5px;
  font-size: 16px;
  line-height: 16px;
  color: ${COLORS.violetRed};
`;

export const WebinarDate = styled.p`
  margin-left: 5px;
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 21px;
  font-weight: normal;
  font-family: 'Poppins';
  color: ${COLORS.resolutionBlue};
`;

export const WebinarSchedule = styled.div`
  min-height: 32px;
  padding-bottom: 5px;
  display: flex;
  align-items: flex-end;
  color: ${COLORS.resolutionBlue};
  font-size: 36px;
  line-height: 32px;
  font-family: 'Poppins';
  font-weight: Bold;
  border-bottom: 1px solid #d5d5d5;
`;

export const CalendarContainer = styled.img`
  width: 62px;
  height: 66px;
  margin: 0 auto;

  @media (max-width: ${TABLET_992}px) {
    width: 92px;
    height: 96px;
  }
`;


export const WebinarTitleTooltip = styled.div`
  padding: 10px;
  bottom: 54%;
  left: 10%;
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  text-decoration: none;
  background-color: ${COLORS.white};
`;

export const WebinarTitleContainer = styled.div`
  min-height: 52px;
  padding-top: 10px;
  position: relative;

  &:hover {
    .training-title-tooltip {
      display: flex;
    }
  }
`;
